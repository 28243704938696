import Vue from 'vue'
import VueRouter from 'vue-router'
import Authentications from './authentications'
import ProfileAndSite from './profile-site'
import Reports from './reports'
import Contacts from './contacts'
import TextAppSubscribers from './text-app-subscribers'
import Errors from './errors'
import AddNewSite from './add-new-site'
import manageSite from './manage-site'
import AddNewCapture from './add-new-capture'
Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [{
        path: '/',
        name: 'home',
        component: () =>
            import('@/views/home/Index.vue'),
        meta: {
            pageTitle: 'Home',
            // breadcrumb: [{
            //     text: 'Home',
            //     active: true,
            //  },],
        },
    }, {
        path: '/summary',
        name: 'summary-page',
        component: () =>
            import('@/views/summary/Index.vue'),
        meta: {
            pageTitle: 'Summary',
        },
    }, {
        path: '/manage-site',
        name: 'manage-site',
        component: () =>
            import('@/views/manage-site/ManageSiteCapture.vue'),
        meta: {
            pageTitle: 'Manage Site Captures',
        },
    }, {
        path: '/take-screenshot',
        name: 'take-screenshot',
        component: () =>
            import('@/views/take-screenshot/TakeScreenshot.vue'),
        meta: {
            pageTitle: 'Take Screenshot',
        },
    }, ...ProfileAndSite, ...Authentications, ...Contacts,...TextAppSubscribers, ...Reports, ...AddNewSite, ...manageSite, ...AddNewCapture, ...Errors,],
});
router.beforeEach((to, from, next) => {

    if (localStorage.getItem('isAuthenticatedUser') == 0 && to.name != "login") {
        next('/login'); return;
    }

    next();
});
/*
 * ? For splash screen
 * Remove afterEach hook if you are not using splash screen
 */
router.afterEach(() => {
    /* Remove initial loading */
    const appLoading = document.getElementById('loading-bg');
    if (appLoading) {
        appLoading.style.display = 'none';
    }
})
export default router
import http from '../HttpRequest.js'
class TextAppSubscribersAPI {
    get(URL = "", params = {}) {
        return http.get(`api/textapp-subscribers/${URL}`, { params })
    }
    post(URL = "", params = {}) {
        var formData = new FormData();
        if (params) {
            for (var key in params) {
                formData.append(key, params[key])
            }
        }
        return http.post(`api/textapp-subscribers/${URL}`, formData);
    }
}
export default new TextAppSubscribersAPI()
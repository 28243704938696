export default [{
    path: '/textapp-subscribers',
    name: 'textapp-subscribers',
    component: () =>
        import('@/views/textapp-subscriber/TextAppSubscribersList.vue'),
    meta: {
        pageTitle: 'TextApp Subscribers',
        breadcrumb: [{
            text: 'Contacts',
            active: 'text-dark'
        }],
    },
},]
